import * as R from 'ramda';
import axios from 'axios';

// const propEqual = (json) => (env) => (toBe) => (prop) => {
//   const fromJson = R.prop(prop, json);
//   const value = fromJson === undefined ? R.prop(prop, env) : fromJson;
//   return value === toBe;
// };
const getProp = (json) => (env) => (prop) => {
  const fromJson = R.prop(prop, json);
  return fromJson === undefined ? R.prop(prop, env) : fromJson;
};

export const prepareEnv = async () => {
  let json;
  try {
    const config = await axios.get('/config.json');
    json = typeof config.data === 'object' ? config.data : {};
  } catch {
    json = {};
  }

  const variable = getProp(json)(process.env);

  baseURL = variable('REACT_APP_BASE_URL');
  domain = variable('REACT_APP_DOMAIN');
  clientId = variable('REACT_APP_CLIENT_ID');
  redirectUri = variable('REACT_APP_REDIRECT_URI');
  audience = variable('REACT_APP_AUDIENCE');
  responseType = variable('REACT_APP_RESPONSE_TYPE');
  scope = variable('REACT_APP_SCOPE');
  logoutURL = variable('REACT_APP_LOGOUT_URL');
  fastspringAccesskey = variable('REACT_APP_ACCESSKEY');
  storefrontURL = variable('REACT_APP_STOREFRONT_URL');
  sdkURL = variable('REACT_APP_SDK_URL');
  apiURL = variable('REACT_APP_API_URL');

  isDevelopment = baseURL === 'https://dev.plus1marketplace.danfoss.com';
  isStaging = baseURL === 'https://stg.plus1marketplace.danfoss.com';
  isSandbox = baseURL === 'https://sbx.plus1marketplace.danfoss.com';
  isProduction = baseURL === 'https://plus1marketplace.danfoss.com';
  if (isDevelopment) {
    portalURL = 'https://dev.plus1connect.danfoss.com';
  } else if (isStaging) {
    portalURL = 'https://stg.plus1connect.danfoss.com';
  } else if (isSandbox) {
    portalURL = 'https://sbx.plus1connect.danfoss.com';
  } else if (isProduction) {
    portalURL = 'https://plus1connect.danfoss.com';
  } else {
    portalURL = 'https://dev.plus1connect.danfoss.com';
  }
};

export let baseURL;
export let domain;
export let clientId;
export let redirectUri;
export let audience;
export let responseType;
export let scope;
export let logoutURL;
export let fastspringAccesskey;
export let storefrontURL;
export let sdkURL;
export let apiURL;
export let portalURL;
export let isDevelopment;
export let isStaging;
export let isSandbox;
export let isProduction;
