import * as env from './config/env';

const prepareEnv = async () => {
  await env.prepareEnv();
};

prepareEnv()
  .then(() => {
    return import('./main');
  })
  .catch((err) => {
    console.log('Failed', err);
  });
